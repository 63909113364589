import React, { useEffect, useState } from "react";
import { Box, Center, Flex, Image, Text } from "@chakra-ui/react";
import { slideshow } from "../../components/common/constants";
import { BsCircle, BsFillCircleFill } from "react-icons/bs";

const SideBarr = () => {
  const [slideIndex, setSlideIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setSlideIndex((slideIndex) => (slideIndex + 1) % slideshow.length);
    }, 4000);

    return () => clearInterval(interval);
  }, []);

  const renderCircle = (index) => {
    const isActive = index === slideIndex;
    const color = isActive ? "#2463EB" : "#2463EB";
    const opacity = isActive ? 1 : 0.5;
    return (
      <Box key={index}>
        {isActive ? (
          <BsFillCircleFill size="10px" color={color} opacity={opacity} />
        ) : (
          <BsCircle size="10px" color={color} opacity={opacity} />
        )}
      </Box>
    );
  };

  return (
    <Box
      pl="52px"
      pt="40px"
      minH={"100vh"}
      pos="fixed"
      right="0"
      w="50%"
      pb="30px"
      bg="#0F172B"
    >
      <Flex
        w="fit-content"
        bg="#F3C948"
        px="12px"
        rounded="full"
        gap="4px"
        py="5px"
      >
        <Image src="/assets/verified.svg" />
        <Text>Edupali</Text>
      </Flex>
      <Flex
        minH="80vh"
        align="center"
        justifyContent="center"
        flexDirection="column"
      >
        <Flex flexDir="column" align="center" justifyContent="center" flex="1">
          <Box w="450px" pos="relative" h="360px" overflow="hidden">
            <Flex
              pos="absolute"
              w={`${slideshow.length}00%`}
              h="100%"
              transition={`transform ${4000 / 1000}s ease-in-out`}
              transform={`translateX(-${
                slideIndex * (100 / slideshow.length)
              }%)`}
            >
              {slideshow.map((data, i) => (
                <Box
                  key={i}
                  w={`${100 / slideshow.length}%`}
                  h="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Image h="355px" w="400px" src={data} />
                </Box>
              ))}
            </Flex>
          </Box>

          <Center flexDir="column" textAlign="center" mt="40px">
            <Text color="#F4BD50" fontSize="24px" fontWeight={600}>
              Parents Benefits
            </Text>

            <Text color="#fff" mt={5}>
              Ensure an uninterrupted learning experience for your children
            </Text>
          </Center>
        </Flex>
        <Flex gap="15px" mt="30px">
          {[...Array(slideshow.length)].map((_, index) => (
            <Box key={index}>{renderCircle(index)}</Box>
          ))}
        </Flex>
      </Flex>
    </Box>
  );
};

export default SideBarr;
