import React from "react";
import { Flex, Heading } from "@chakra-ui/react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useLogOut } from "../../utils/helper";

const GoBack = ({ title, onClose, reset, back, route, reload, logout }) => {
  const navigate = useNavigate();
  const logOut = useLogOut();
  const reloaded = () => {
    window.location.reload();
  };

  return (
    <Flex
      cursor="pointer"
      gap=".3rem"
      color={route || reload || logout ? "red" : back && ""}
      onClick={() =>
        back
          ? navigate(-1)
          : route
          ? navigate(route)
          : logout
          ? logOut()
          : reload
          ? reloaded()
          : reset
          ? sessionStorage.removeItem("options")
          : onClose()
      }
      alignItems="center"
    >
      {back || reset ? <IoIosArrowBack /> : <AiOutlineCloseCircle size={15} />}
      <Heading fontSize={{ base: "16px", md: "15px" }} fontWeight={400}>
        {title}
      </Heading>
    </Flex>
  );
};

export default GoBack;
