import React, { useEffect } from "react";
import { Box, Flex, Image, useMediaQuery } from "@chakra-ui/react";
import SideBar from "./AuthLayout/SideBar";
import SideBarr from "./NonAuthLayout/SideBarr";
import Header from "./AuthLayout/Header";
import AuthBase from "../components/common/AuthBase";
import { useLocation } from "react-router-dom";
import { useGetUser } from "../services/query/account";

export const AuthLayout = ({ children }) => {
  const [isMobile] = useMediaQuery("(max-width: 991px)");
  const { data: userData } = useGetUser();

  useEffect(() => {
    if (userData) {
      localStorage.setItem(
        "email",
        userData?.data?.user?.email?.includes("fastamoni")
          ? userData?.data?.user?.phone?.replace("+234", "0")
          : userData?.data?.user?.email
      );
    }
  }, [userData]);
  
  useEffect(() => {
    const events = [
      "mousedown",
      "mousemove",
      "keydown",
      "scroll",
      "touchstart",
    ];
    const resetIdleTimer = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        sessionStorage.clear();
        window.location.href = "/login";
      }, 120000);
    };
    events.forEach((event) => window.addEventListener(event, resetIdleTimer));

    let timeoutId = setTimeout(() => {
      sessionStorage.clear();
      window.location.href = "/login";
    }, 120000);

    return () => {
      clearTimeout(timeoutId);
      events.forEach((event) =>
        window.removeEventListener(event, resetIdleTimer)
      );
    };
  }, []);

  return (
    <Box
      overflowX={isMobile ? "hidden" : ""}
      color="#000"
      fontFamily="Poppins"
      bg="#fafafa"
      position="relative"
    >
      <Box minH="100vh" overflowX="hidden">
        <Header />
        {!isMobile && <SideBar />}
        <Box
          w="100%"
          pt="125px"
          minH="100vh"
          color="#828282"
          pl={!isMobile ? "320px" : "20px"}
          pr={!isMobile ? "50px" : "20px"}
          pb={10}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export const NonAuthLayout = ({ children }) => {
  const [isMobile] = useMediaQuery("(max-width: 991px)");
  const location = useLocation();

  return (
    <Box color="#000" fontFamily="Poppins" bg="#fff" position="relative">
      <Flex minH="100vh">
        <Flex pb="30px" flexDir="column" w={!isMobile ? "50%" : "100%"}>
          <Box bg="#fff" pos="sticky" top="0" py="43px" px="28px">
            <Flex gap="13px" align="center">
              <Image src="/assets/logo.png" w="140px" h="50px" />
            </Flex>
          </Box>
          <Box
            flex="1"
            mb={location.pathname === "/login" && "50px"}
            pt={!isMobile ? "20px" : 10}
            px={!isMobile ? "80px" : 10}
          >
            {children}
          </Box>
          <AuthBase />
        </Flex>
        {!isMobile && <SideBarr />}
      </Flex>
    </Box>
  );
};
