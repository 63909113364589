import { lazy } from "react";
import WithSuspense from "../components/Loaders/WithSuspense";
import { PUBLIC_PATHS, PRIVATE_PATHS } from "./constants";
import { Navigate } from "react-router-dom";

const {
  LOGIN,
  REG,
  REG_TWO,
  VER_PASS,
  CH_PASS,
  FOG_PASS,
  VER_OTP,
  CH_NEW_PASS,
} = PUBLIC_PATHS;

const {
  OVERVIEW,
  PENDING_ENROLLMENT,
  PLAN,
  REPORT,
  SETUP,
  TRAN_LOG,
  NOTI,
  SETTINGS,
  PORTAL,
} = PRIVATE_PATHS;

const Login = WithSuspense(lazy(() => import("../pages/Authentication/Login")));
const Register = WithSuspense(
  lazy(() => import("../pages/Authentication/Register"))
);
const AcceptInvitation = WithSuspense(
  lazy(() => import("../pages/Authentication/AcceptInvitation"))
);
const ChangePassword = WithSuspense(
  lazy(() => import("../pages/Authentication/ChangePassword"))
);
const ChangeNewPassword = WithSuspense(
  lazy(() => import("../pages/Authentication/ChangeNewPassword"))
);
const VerifyOtp = WithSuspense(
  lazy(() => import("../pages/Authentication/VerifyOtp"))
);
const ForgotPassword = WithSuspense(
  lazy(() => import("../pages/Authentication/ForgotPassword"))
);
const VerifyCodePass = WithSuspense(
  lazy(() => import("../pages/Authentication/VerifyCodePass"))
);
const Overview = WithSuspense(lazy(() => import("../pages/Overview/Overview")));
const Setup = WithSuspense(lazy(() => import("../pages/Setup/Setup")));
const Plan = WithSuspense(lazy(() => import("../pages/Setup/Plan")));
const PendingEnrollment = WithSuspense(
  lazy(() => import("../pages/Setup/PendingEnrollment"))
);
const Report = WithSuspense(lazy(() => import("../pages/Report/Report")));
const TranLog = WithSuspense(lazy(() => import("../pages/TranLog/TranLog")));
const Portal = WithSuspense(lazy(() => import("../pages/Portal/Portal")));
const Settings = WithSuspense(lazy(() => import("../pages/Settings/Settings")));

const Notifications = WithSuspense(
  lazy(() => import("../pages/Notifications/Notifications"))
);

export const PUBLIC_ROUTES = [
  { path: LOGIN, element: <Login /> },
  { path: REG, element: <Register /> },
  { path: REG_TWO, element: <AcceptInvitation /> },
  { path: FOG_PASS, element: <ForgotPassword /> },
  { path: CH_PASS, element: <ChangePassword /> },
  { path: VER_PASS, element: <VerifyCodePass /> },
  { path: VER_OTP, element: <VerifyOtp /> },
  { path: "*", element: <Navigate to="/login" replace /> },
];
const user = JSON.parse(sessionStorage.getItem("user"));

export const PRIVATE_ROUTES = [
  { path: OVERVIEW, element: <Overview /> },
  { path: PENDING_ENROLLMENT, element: <PendingEnrollment /> },
  { path: TRAN_LOG, element: <TranLog /> },
  { path: REPORT, element: <Report /> },
  { path: NOTI, element: <Notifications /> },
  { path: SETUP, element: <Setup /> },
  { path: PLAN, element: <Plan /> },
  { path: SETTINGS, element: <Settings /> },
  { path: PORTAL, element: <Portal /> },
  {
    path: "*",
    element: (
      <Navigate
        to={
          !user?.data?.hasWards
            ? "/complete-setup"
            : user?.data?.hasWards && !user?.data?.hasActivePlan
            ? "/plan-setup"
            : user?.data?.hasWards && user?.data?.hasActivePlan && "/dashboard"
        }
        replace
      />
    ),
  },
];
