import axiosInstance from "../axiosInstance";
import * as API from "../url";

export const createPin = async (body) => {
  const res = await axiosInstance.post(API.CREATE_PIN, body);
  return res.data;
};

export const inviteParent = async (body) => {
  const res = await axiosInstance.post(API.INV_PARENT, body);
  return res.data;
};

export const updateUser = async (body) => {
  const res = await axiosInstance.post(API.UPDATE_USER, body);
  return res.data;
};

export const addStudent = async (body) => {
  const res = await axiosInstance.post(API.ADD_SCHOOL, body);
  return res.data;
};

export const verifyPasswordOtp = async (body) => {
  const res = await axiosInstance.post(API.VERIFY_OTP, body);
  return res.data;
};

export const verifyLoginPasswordOtp = async (body) => {
  const res = await axiosInstance.post(API.VERIFY_LOGIN_OTP, body);
  return res.data;
};

export const verifyLoginOtp = async (body) => {
  const res = await axiosInstance.put(API.VERIFY_OTPP, body);
  return res.data;
};

export const sendLoginPasswordOtp = async (body) => {
  const res = await axiosInstance.post(API.SEND_LOGIN_PASS_OTP, body);
  return res.data;
};

export const sendPasswordOtp = async (body) => {
  const res = await axiosInstance.post(API.SEND_PASS_OTP, body);
  return res.data;
};

export const addBank = async (body) => {
  const res = await axiosInstance.post(API.ADD_BANK, body);
  return res.data;
};

export const createPlan = async (body) => {
  const res = await axiosInstance.post(API.CREATE_PLAN, body);
  return res.data;
};

export const confirmTransfer = async (body) => {
  const res = await axiosInstance.post(API.CONFIRM_TRANSFER, body);
  return res.data;
};

export const verifyPayment = async (body) => {
  const res = await axiosInstance.post(API.VERIFY_PAYMENT, body);
  return res.data;
};

export const getUser = async () => {
  const res = await axiosInstance.get(`${API.GET_USER}`);
  return res.data;
};

export const getBank = async ({ queryKey }) => {
  const [, id] = queryKey;
  const res = await axiosInstance.get(`${API.GET_BANK}/${id}`);
  return res.data;
};

export const getPlanTransaction = async ({ queryKey }) => {
  const [, parentId] = queryKey;
  const res = await axiosInstance.get(
    `${API.GET_PLAN_TRANSACTION}?parentId=${parentId}&page=1`
  );
  return res.data;
};

export const getParent = async ({ queryKey }) => {
  const [, id] = queryKey;
  const res = await axiosInstance.get(`${API.GET_PARENT}?organizationId=${id}`);
  return res.data;
};

export const setTerm = async (body) => {
  const res = await axiosInstance.post(API.SET_TERM, body);
  return res.data;
};

export const createProfile = async (body) => {
  const res = await axiosInstance.post(API.CREATE_PROFILE, body);
  return res.data;
};

export const getSchool = async ({ queryKey }) => {
  const [, id] = queryKey;
  const res = await axiosInstance.get(`${API.GET_SCHOOL}/${id}`);
  return res.data;
};

export const getAllDivisions = async () => {
  const res = await axiosInstance.get(API.GET_DIVISION);
  return res.data;
};

export const getTerms = async () => {
  const res = await axiosInstance.get(
    `${API.GET_TERM}?offset=&limit=&schoolId=${id}`
  );
  return res.data;
};
