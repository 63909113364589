import { useMutation, useQuery } from "react-query";
import {
  changePassword,
  deleteNoti,
  getBank,
  uploadLogo,
  getNoti,
  readNoti,
  verifyParent,
  uploadId,
  getBankTransfer,
  createAcc,
  createLencoAcc,
  getTrans,
  getPlanCount,
  getTerms,
  getAllDivisions,
  getExtra,
  getDefaultExtras,
  addStudent,
  acceptInviteCode,
  getOverdue,
  getParentPlans,
  getParentWards,
  getDailyPlanSummary,
  getWeeklyPlanSummary,
  getMonthlyPlanSummary,
  getOneOffPlanSummary,
  getFullYearPlanSummary,
  getNewStudentEnrollment,
  getPlanTotalPaid,
  getPlanOutstanding,
  getPlanTotalAmount,
  changeNewParentPassword,
} from "../api/parent";

import * as queryKey from "../queryKeys";

export const useGetBankTransfer = (options = {}) => {
  const { mutate, isLoading, data } = useMutation(getBankTransfer, {
    mutationKey: queryKey.TRANSFER_AMT,
    ...options,
  });

  return { mutate, isLoading, data };
};

export const useVerifyParent = (options = {}) => {
  const { mutate, isLoading } = useMutation(verifyParent, {
    mutationKey: queryKey.VERIFY_PARENT,
    ...options,
  });

  return { mutate, isLoading };
};

export const useCreateAcc = (options = {}) => {
  const { mutate, isLoading } = useMutation(createAcc, {
    mutationKey: queryKey.CREATE_ACC,
    ...options,
  });

  return { mutate, isLoading };
};

export const useGetTrans = (id = "", options = {}) => {
  const { data, isLoading, refetch } = useQuery(
    [queryKey.GET_TRANS, id],
    getTrans,
    {
      ...options,
    }
  );

  return { data, isLoading, refetch };
};

export const useGetPlanCount = (options = {}) => {
  const { data, isLoading, refetch } = useQuery(
    [queryKey.GET_PLAN_COUNT],
    getPlanCount,
    {
      ...options,
    }
  );

  return { data, isLoading, refetch };
};

export const useGetOverdue = (options = {}) => {
  const { data, isLoading, refetch } = useQuery("OVERDUE", getOverdue, {
    ...options,
  });

  return { data, isLoading, refetch };
};

export const useCreateLencoAcc = (options = {}) => {
  const { mutate, isLoading } = useMutation(createLencoAcc, {
    mutationKey: queryKey.CREATE_LENCO,
    ...options,
  });

  return { mutate, isLoading };
};

export const useUploadId = (options = {}) => {
  const { mutate, isLoading } = useMutation(uploadId, {
    mutationKey: queryKey.UPLOAD_ID,
    ...options,
  });

  return { mutate, isLoading };
};

export const useUploadLogo = (options = {}) => {
  const { mutate, isLoading } = useMutation(uploadLogo, {
    mutationKey: queryKey.UPLOAD_LOGO,
    ...options,
  });

  return { mutate, isLoading };
};

export const useChangePassword = (options = {}) => {
  const { mutate, isLoading } = useMutation(changePassword, {
    mutationKey: queryKey.CHANGE_PASS,
    ...options,
  });

  return { mutate, isLoading };
};

export const useChangeNewParentPassword = (options = {}) => {
  const { mutate, isLoading } = useMutation(changeNewParentPassword, {
    mutationKey: queryKey.CHANGE_NEW_PARENT_PASS,
    ...options,
  });

  return { mutate, isLoading };
};
export const useDeleteNoti = (options = {}) => {
  const { mutate, isLoading } = useMutation(deleteNoti, {
    mutationKey: queryKey.DELETE_NOTI,
    ...options,
  });

  return { mutate, isLoading };
};

export const useReadNoti = (options = {}) => {
  const { mutate, isLoading } = useMutation(readNoti, {
    mutationKey: queryKey.READ_NOTI,
    ...options,
  });

  return { mutate, isLoading };
};

export const useGetBank = (options = {}) => {
  const { mutate, isLoading, data } = useMutation(getBank, {
    mutationKey: queryKey.GET_BANK,
    ...options,
  });

  return { mutate, isLoading, data };
};

export const useGetNoti = (id = "", options = {}) => {
  const { data, isLoading, refetch } = useQuery(
    [queryKey.GET_NOTI, id],
    getNoti,
    {
      ...options,
    }
  );

  return { data, isLoading, refetch };
};

export const useGetTerms = (id = "", options = {}) => {
  const { data, isLoading, refetch } = useQuery(
    [queryKey.GET_TERM, id],
    getTerms,
    {
      ...options,
    }
  );

  return { data, isLoading, refetch };
};

export const useGetAllDivisions = (id = "", options = {}) => {
  const { data, isLoading, refetch } = useQuery(
    [queryKey.GET_DIVISION, id],
    getAllDivisions,
    {
      ...options,
    }
  );

  return { data, isLoading, refetch };
};

export const useGetExtras = (options = {}) => {
  const { mutate, data, isLoading } = useMutation(getExtra, {
    mutationKey: queryKey.GET_EXTRA,
    ...options,
  });

  return { mutate, data, isLoading };
};

export const useGetDefaultExtras = (id = "", options = {}) => {
  const { data, isLoading, refetch } = useQuery(
    [queryKey.GET_DEFAULT_EXTRAS, id],
    getDefaultExtras,
    {
      ...options,
    }
  );

  return { data, isLoading, refetch };
};

export const useAddStudent = (options = {}) => {
  const { mutate, data, isLoading } = useMutation(addStudent, {
    mutationKey: queryKey.UPDATE_STUDENT,
    ...options,
  });

  return { mutate, data, isLoading };
};

export const useAcceptInviteLink = (options = {}) => {
  const { mutate, isLoading, data } = useMutation(acceptInviteCode, {
    mutationKey: queryKey.ACCEPT_INVITE_CODE,
    ...options,
  });

  return { mutate, isLoading, data };
};

export const useGetParentPlans = (options = {}) => {
  const { data, isLoading, refetch } = useQuery(
    [queryKey.GET_PARENT_PLANS],
    getParentPlans,
    {
      ...options,
    }
  );

  return { data, isLoading, refetch };
};

export const useGetParentWards = (options = {}) => {
  const { data, isLoading, refetch } = useQuery(
    [queryKey.GET_PARENT_WARDS],
    getParentWards,
    {
      ...options,
    }
  );

  return { data, isLoading, refetch };
};

export const useGetDailyPlanDetails = (options = {}) => {
  const { data, isLoading, refetch } = useQuery(
    [queryKey.GET_DAILY_PLAN_SUMMARY],
    getDailyPlanSummary,
    {
      ...options,
    }
  );

  return { data, isLoading, refetch };
};

export const useGetWeeklyPlanDetails = (options = {}) => {
  const { data, isLoading, refetch } = useQuery(
    [queryKey.GET_WEEKLY_PLAN_SUMMARY],
    getWeeklyPlanSummary,
    {
      ...options,
    }
  );

  return { data, isLoading, refetch };
};

export const useGetMonthlyPlanDetails = (options = {}) => {
  const { data, isLoading, refetch } = useQuery(
    [queryKey.GET_MONTHLY_PLAN_SUMMARY],
    getMonthlyPlanSummary,
    {
      ...options,
    }
  );

  return { data, isLoading, refetch };
};

export const useGetOneOffPlanDetails = (options = {}) => {
  const { data, isLoading, refetch } = useQuery(
    [queryKey.GET_ONE_OFF_PLAN_SUMMARY],
    getOneOffPlanSummary,
    {
      ...options,
    }
  );

  return { data, isLoading, refetch };
};

export const useGetFullYearPlanDetails = (options = {}) => {
  const { data, isLoading, refetch } = useQuery(
    [queryKey.GET_FULL_YEAR_PLAN_SUMMARY],
    getFullYearPlanSummary,
    {
      ...options,
    }
  );

  return { data, isLoading, refetch };
};

export const useGetNewStudentEnrollment = (options = {}) => {
  const { data, isLoading, refetch } = useQuery(
    [queryKey.GET_NEW_STUDENT_ENROLLMENT],
    getNewStudentEnrollment,
    {
      ...options,
    }
  );

  return { data, isLoading, refetch };
};

export const useGetPlanTotalAmount = (parentId = "", options = {}) => {
  const { data, isLoading, refetch } = useQuery(
    [queryKey.GET_PLAN_TOTAL_AMOUNT, parentId],
    getPlanTotalAmount,
    {
      ...options,
    }
  );

  return { data, isLoading, refetch };
};

export const useGetPlanOutstanding = (parentId = "", options = {}) => {
  const { data, isLoading, refetch } = useQuery(
    [queryKey.GET_PLAN_OUTSTANDING, parentId],
    getPlanOutstanding,
    {
      ...options,
    }
  );

  return { data, isLoading, refetch };
};

export const useGetPlanTotalPaid = (parentId = "", options = {}) => {
  const { data, isLoading, refetch } = useQuery(
    [queryKey.GET_PLAN_TOTAL_PAID, parentId],
    getPlanTotalPaid,
    {
      ...options,
    }
  );

  return { data, isLoading, refetch };
};
