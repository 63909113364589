export const REG_TWO = "REG_TWO";
export const GET_EXTRA = "GET_EXTRA";
export const GET_DEFAULT_EXTRAS = "GET_DEFAULT_EXTRAS";
export const UPDATE_STUDENT = "UPDATE_STUDENT";
export const BANK_CODE = "BANK_CODE";
export const BANKS = "BANKS";
export const VALIDATE_BANK = "VALIDATE_BANK";
export const CREATE_SCHOOL_OWNER = "CREATE_SCHOOL_OWNER";
export const RESEND_LOGIN_OTP = "RESEND_LOGIN_OTP";
export const CREATE_SCHOOL = "CREATE_SCHOOL";
export const VERIFY_EMAIL = "VERIFY_EMAIL";
export const SEND_EMAIL_TOKEN = "SEND_EMAIL_TOKEN";
export const LOGIN = "LOGIN";
export const CREATE_PIN = "CREATE_PIN";
export const VERIFY_OTP = "VERIFY_OTP";
export const UPDATE_USER = "UPDATE_USER";
export const SEND_PASS_OTP = "SEND_PASS_OTP";
export const CREATE_PLAN = "CREATE_PLAN";
export const CONFIRM_TRANSFER = "CONFIRM_TRANSFER";
export const VERIFY_PAYMENT = "VERIFY_PAYMENT";
export const VERIFY_YEAR = "VERIFY_YEAR";
export const CREATE_YEAR_PLAN = "CREATE_YEAR_PLAN";
export const CREATE_ONE_PLAN = "CREATE_ONE_PLAN";
export const VERIFY_PARENT = "VERIFY_PARENT";
export const CREATE_ACC = "CREATE_ACC";
export const GET_TRANS = "GET_TRANS";
export const GET_PLAN_COUNT = "GET_PLAN_COUNT";
export const CREATE_LENCO = "CREATE_LENCO";
export const TRANSFER_AMT = "TRANSFER_AMT";
export const CHANGE_PASS = "CHANGE_PASS";
export const CHANGE_NEW_PARENT_PASS = "CHANGE_NEW_PARENT_PASS";
export const UPLOAD_LOGO = "UPLOAD_LOGO";
export const UPLOAD_ID = "UPLOAD_ID";
export const DELETE_NOTI = "DELETE_NOTI";
export const READ_NOTI = "READ_NOTI";
export const GET_NOTI = "GET_NOTI";
export const COUNTRIES = "COUNTRIES";
export const GET_USER = "GET_USER";
export const GET_PLAN_TRANSACTION = "GET_PLAN_TRANSACTION";
export const GET_BANK = "GET_BANK";
export const GET_PARENT = "GET_PARENT";
export const INV_PARENT = "INV_PARENT";
export const ADD_SCHOOL = "ADD_SCHOOL";
export const ADD_BANK = "ADD_BANK";
export const CREATE_PROFILE = "CREATE_PROFILE";
export const SET_TERM = "SET_TERM";
export const GET_SCHOOL = "GET_SCHOOL";
export const GET_DIVISION = "GET_DIVISION";
export const GET_TERM = "GET_TERM";
export const ACCEPT_INVITE_CODE = "ACCEPT_INVITE_CODE";
export const GET_CODE = "GET_CODE";
export const SIGN_UP_INVITE = "SIGN_UP_INVITE";
export const GET_PARENT_PLANS = "GET_PARENT_PLANS";
export const GET_PARENT_WARDS = "GET_PARENT_WARDS ";
export const GET_ONE_OFF_PLAN_SUMMARY = "GET_ONE_OFF_PLAN_SUMMARY";
export const GET_FULL_YEAR_PLAN_SUMMARY = "GET_FULL_YEAR_PLAN_SUMMARY";
export const GET_WEEKLY_PLAN_SUMMARY = "GET_WEEKLY_PLAN_SUMMARY";
export const GET_DAILY_PLAN_SUMMARY = "GET_DAILY_PLAN_SUMMARY";
export const GET_MONTHLY_PLAN_SUMMARY = "GET_MONTHLY_PLAN_SUMMARY";
export const GET_NEW_STUDENT_ENROLLMENT = "GET_NEW_STUDENT_ENROLLMENT";
export const GET_PLAN_TOTAL_PAID = " GET_PLAN_TOTAL_PAID";
export const GET_PLAN_OUTSTANDING = "GET_PLAN_OUTSTANDING";
export const GET_PLAN_TOTAL_AMOUNT = "GET_PLAN_TOTAL_AMOUNT";
