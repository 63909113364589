import React from "react";
import { Box, Flex, Text } from "@chakra-ui/layout";
import { IoMdMenu } from "react-icons/io";
import { Avatar, useDisclosure, useMediaQuery } from "@chakra-ui/react";
import SideDrawer from "./SideDrawer";
import { useGetUser } from "../../services/query/account";
import { Main } from "../../components/data/Notification/Tabs/Main";

const Header = () => {
  const [isMobile] = useMediaQuery("(max-width: 991px)");
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { data } = useGetUser();

  return (
    <Flex
      flexDirection="column"
      bg="darkBg"
      pos="fixed"
      w="100%"
      zIndex="5"
      py={isMobile ? "30px" : "25px"}
      color="#fff"
    >
      <Flex
        justifyContent="space-between"
        align="center"
        pl={isMobile ? "20px" : "320px"}
        pr={isMobile ? "20px" : "48px"}
        w="full"
      >
        <Flex justifyContent="space-between" align="center" w="full">
          <Text color="orangeBg" fontSize="23px" fontWeight={700}>
            {data?.data?.school?.name ?? "--"}
          </Text>
          {isMobile && (
            <Flex
              color="#BDBDBD"
              borderRadius="20px"
              border="1px solid rgba(104, 132, 202, 0.5)"
              p="7px"
              onClick={onOpen}
              w="fit-content"
              ml={isMobile ? "25px" : "320px"}
              cursor="pointer"
            >
              <IoMdMenu size="20px" />
            </Flex>
          )}
        </Flex>

        {!isMobile && (
          <Flex
            align="center"
            gap="18px"
            whiteSpace="nowrap"
            w={isMobile ? "" : "35%"}
          >
            <Main />
            <Flex justifyContent="space-between" w="full" align="center">
              <Flex gap="20px" align="center">
                <Avatar
                  borderLeft="3px solid #2463EB"
                  borderBottom="3px solid #2463EB"
                  borderRight="3px solid #2463EB"
                  cursor="pointer"
                  borderTop="3px solid #F1EBF9"
                  p={0.5}
                  rounded="full"
                  w="44px"
                  h="44px"
                  src={data?.data?.user?.avatar ?? "--"}
                />

                <Box>
                  <Text fontSize="13px">{`${
                    data?.data?.user?.firstName ?? "--"
                  } ${data?.data?.user?.lastName ?? "--"}`}</Text>
                  <Text fontSize="11px" color="orangeBg">
                    {data?.data?.user?.email ?? "--"}
                  </Text>
                </Box>
              </Flex>
            </Flex>
          </Flex>
        )}
      </Flex>

      <SideDrawer isOpen={isOpen} onClose={onClose} />
    </Flex>
  );
};

export default Header;
