import React from "react";
import { Flex, Image, Text } from "@chakra-ui/react";

const AuthBase = () => {
  return (
    <Flex
      fontSize="16px"
      gap="31px"
      justifyContent="center"
      align="center"
      mt="20px"
    >
      <Image src="/assets/check.svg" w="32px" h="32px" />
      <Text>Secure and Reliable</Text>
    </Flex>
  );
};

export default AuthBase;
