import React from "react";
import { Box, Flex, Text } from "@chakra-ui/layout";
import { Image } from "@chakra-ui/image";
import { useLogOut } from "../../utils/helper";
import { IoIosArrowForward } from "react-icons/io";
import {
  accounts,
  activeStyle,
  general,
  payments,
} from "../../components/common/constants";
import { NavLink, useNavigate } from "react-router-dom";
import { WarningIcon } from "../../assets";
import { useGetUser } from "../../services/query/account";
import useCustomToast from "../../utils/notifications";
import { useGetParentPlans } from "../../services/query/parent";

const SideBar = () => {
  const logOut = useLogOut();
  const { warningToast } = useCustomToast();
  const navigate = useNavigate();
  const { data: parent } = useGetUser();
  const { data: parentPlans } = useGetParentPlans();

  window.$zoho = window.$zoho || {};
  window.$zoho.salesiq = window.$zoho.salesiq || {
    widgetcode: process.env.REACT_APP_WIDGET_CODE,
    values: {},
    ready: function () {},
  };
  const d = document;
  let s;
  s = d.createElement("script");
  s.type = "text/javascript";
  s.id = "zsiqscript";
  s.defer = true;
  s.src = "https://salesiq.zoho.com/widget";
  let t;
  t = d.getElementsByTagName("script")[0];
  t.parentNode.insertBefore(s, t);

  return (
    <Flex
      flexDir="column"
      justifyContent="space-between"
      position={"fixed"}
      zIndex="5"
      w="287px"
      borderRight="0.5px solid rgba(104, 132, 202, 0.5)"
      bg="#0F172B"
    >
      <Box h="100vh" overflowY="scroll">
        <Flex
          bg="#0F172B"
          pos="sticky"
          px="10px"
          py="21px"
          top="0"
          borderBottom="0.5px solid rgba(104, 132, 202, 0.5)"
          justifyContent="space-between"
          align="center"
        >
          <Flex align="center">
            <Image src="/assets/side-logo.svg" w="140px" h="50px" />
          </Flex>
          <Box px="12px" py="5px" bg="#fff" color="#0F172B" rounded="full">
            <Text fontSize="12px">Parent Portal</Text>
          </Box>
        </Flex>
        {parentPlans?.count === 0 && (
          <Box
            mx="20px"
            my="30px"
            bg="#fff"
            py="16px"
            px="22px"
            borderRadius="10px"
          >
            <Flex
              borderBottom="1px solid #e0e0e0"
              pb="12px"
              gap="7px"
              align="center"
            >
              <Text fontSize="14px" fontWeight={500}>
                PENDING
              </Text>
              <Flex
                align="center"
                justifyContent="center"
                bg="#000"
                rounded="full"
                fontSize="10px"
                w="20px"
                h="20px"
                color="#2463EB"
              >
                {parentPlans?.count === 0
                  ? "02"
                  : parentPlans?.count > 0
                  ? "01"
                  : parentPlans?.count === 0
                  ? "01"
                  : parentPlans?.count > 0}
              </Flex>
            </Flex>
            {(parentPlans?.count === 0 ||
              parentPlans?.count > 0 ||
              parentPlans?.count === 0) && (
              <NavLink
                to={
                  parent?.data?.wardCount === 0
                    ? "/complete-setup"
                    : parent?.data?.wardCount > 0 &&
                      parent?.data?.user?.status === "Pending-Enrollment"
                    ? "/pending-enrollment"
                    : parent?.data?.wardCount > 0 &&
                      !parent?.data?.user?.hasActivePlans &&
                      parent?.data?.user?.status !== "Pending-Enrollment"
                    ? "/plan-setup"
                    : "/complete-setup"
                }
              >
                <Flex
                  cursor="pointer"
                  mt="20px"
                  justifyContent="space-between"
                  align="center"
                  onClick={() =>
                    parent?.data?.wardCount > 0 &&
                    parent?.data?.user?.status === "Pending-Enrollment"
                      ? warningToast("Please wait for approval")
                      : ""
                  }
                >
                  <Flex gap="12px" align="center">
                    <WarningIcon fill="#E81313" />
                    <Text fontSize="14px" color="#E81313">
                      Complete Setup
                    </Text>
                  </Flex>
                  <IoIosArrowForward color="#666666" />
                </Flex>
              </NavLink>
            )}

            <NavLink
              onClick={() =>
                parent?.data?.wardCount === 0
                  ? warningToast("Please Enroll a ward")
                  : parent?.data?.wardCount > 0 &&
                    parent?.data?.user?.status === "Pending-Enrollment"
                  ? warningToast("Please wait for approval")
                  : ""
              }
              to={
                parent?.data?.wardCount === 0
                  ? ""
                  : parent?.data?.wardCount > 0 &&
                    parent?.data?.user?.status === "Pending-Enrollment"
                  ? "/pending-enrollment"
                  : "/plan-setup"
              }
            >
              <Flex
                gap="11px"
                mt="25px"
                px="18px"
                py="15px"
                borderRadius="10px"
              >
                <Image src="/assets/users.svg" />
                <Text fontSize="14px" fontWeight={500} color="#2463EB">
                  {" "}
                  Choose a Plan
                </Text>
              </Flex>
            </NavLink>
          </Box>
        )}

        <Box mx="20px" mt="30px">
          <Text mb="10px" fontSize="12px" fontWeight={600} color="#fff">
            GENERAL
          </Text>
          {general?.map((item, i) => (
            <Box
              key={i}
              onClick={() =>
                parent?.data?.wardCount > 0 &&
                parentPlans?.count === 0 &&
                parent?.data?.user?.status === "Pending-Enrollment"
                  ? (warningToast("Please wait for approval"),
                    navigate("/pending-enrollment"))
                  : parentPlans?.count > 0 &&
                    parent?.data?.wardCount > 0 &&
                    parent?.data?.user?.status !== "Pending-Enrollment"
                  ? ""
                  : parent?.data?.wardCount === 0
                  ? warningToast("Please Enroll a Ward")
                  : (warningToast("Please Choose a Plan"),
                    navigate("/plan-setup"))
              }
              _hover={{ backgroundColor: "semiBlue", px: "20px", mx: "-20px" }}
            >
              <NavLink
                to={
                  parentPlans?.count > 0 &&
                  parent?.data?.wardCount > 0 &&
                  parent?.data?.user?.status !== "Pending-Enrollment"
                    ? item.path
                    : parent?.data?.wardCount > 0 &&
                      parentPlans?.count === 0 &&
                      parent?.data?.user?.status === "Pending-Enrollment"
                    ? ""
                    : ""
                }
                style={({ isActive }) =>
                  isActive
                    ? { ...activeStyle }
                    : {
                        ...activeStyle,
                        backgroundColor: "",
                        fontWeight: 400,
                        borderRight: "",
                        color: "gray",
                      }
                }
              >
                <Flex>
                  <Box w="16px" h="16px" mr="11px">
                    {location.pathname === item.path ? item.sec : item.icon}
                  </Box>
                  {item.name}
                </Flex>
              </NavLink>
            </Box>
          ))}
        </Box>

        <Box mt="40px" mx="20px">
          <Text mb="10px" fontSize="12px" fontWeight={600} color="#fff">
            PAYMENTS
          </Text>
          {payments?.map((item, i) => (
            <Box
              key={i}
              onClick={() =>
                parent?.data?.wardCount > 0 &&
                parentPlans?.count === 0 &&
                parent?.data?.user?.status === "Pending-Enrollment"
                  ? (warningToast("Please wait for approval"),
                    navigate("/pending-enrollment"))
                  : parentPlans?.count > 0 &&
                    parent?.data?.wardCount > 0 &&
                    parent?.data?.user?.status !== "Pending-Enrollment"
                  ? ""
                  : parent?.data?.wardCount === 0
                  ? warningToast("Please Enroll a Ward")
                  : (warningToast("Please Choose a Plan"),
                    navigate("/plan-setup"))
              }
              _hover={{ backgroundColor: "semiBlue", px: "20px", mx: "-20px" }}
            >
              <NavLink
                to={
                  parentPlans?.count > 0 &&
                  parent?.data?.wardCount > 0 &&
                  parent?.data?.user?.status !== "Pending-Enrollment"
                    ? item.path
                    : parent?.data?.wardCount > 0 &&
                      parentPlans?.count === 0 &&
                      parent?.data?.user?.status === "Pending-Enrollment"
                    ? ""
                    : ""
                }
                style={({ isActive }) =>
                  isActive
                    ? { ...activeStyle }
                    : {
                        ...activeStyle,
                        backgroundColor: "",
                        fontWeight: 400,
                        borderRight: "",
                        color: "gray",
                      }
                }
              >
                <Flex>
                  <Box w="16px" h="16px" mr="11px">
                    {location.pathname === item.path ? item.sec : item.icon}
                  </Box>
                  {item.name}
                </Flex>
              </NavLink>
            </Box>
          ))}
        </Box>

        <Box mt="40px" mx="20px">
          <Text mb="10px" fontSize="12px" fontWeight={600} color="#fff">
            ACCOUNT
          </Text>
          {accounts?.map((item, i) => (
            <Box
              key={i}
              onClick={() =>
                parent?.data?.wardCount > 0 &&
                parentPlans?.count === 0 &&
                parent?.data?.user?.status === "Pending-Enrollment"
                  ? (warningToast("Please wait for approval"),
                    navigate("/pending-enrollment"))
                  : parentPlans?.count > 0 &&
                    parent?.data?.wardCount > 0 &&
                    parent?.data?.user?.status !== "Pending-Enrollment"
                  ? ""
                  : parent?.data?.wardCount === 0
                  ? warningToast("Please Enroll a Ward")
                  : (warningToast("Please Choose a Plan"),
                    navigate("/plan-setup"))
              }
              _hover={{ backgroundColor: "semiBlue", px: "20px", mx: "-20px" }}
            >
              <NavLink
                to={
                  parentPlans?.count > 0 &&
                  parent?.data?.wardCount > 0 &&
                  parent?.data?.user?.status !== "Pending-Enrollment"
                    ? item.path
                    : parent?.data?.wardCount > 0 &&
                      parentPlans?.count === 0 &&
                      parent?.data?.user?.status === "Pending-Enrollment"
                    ? ""
                    : ""
                }
                style={({ isActive }) =>
                  isActive
                    ? { ...activeStyle }
                    : {
                        ...activeStyle,
                        backgroundColor: "",
                        fontWeight: 400,
                        borderRight: "",
                        color: "gray",
                      }
                }
              >
                <Flex>
                  <Box w="16px" h="16px" mr="11px">
                    {location.pathname === item.path ? item.sec : item.icon}
                  </Box>
                  {item.name}
                </Flex>
              </NavLink>
            </Box>
          ))}
        </Box>

        <Flex
          fontSize="14px"
          fontWeight={400}
          cursor="pointer"
          color="#E81313"
          onClick={logOut}
          align="center"
          gap="11px"
          mb="110px"
          mt="40px"
          mx="25px"
        >
          <Image src="/assets/logout.svg" />
          Log Out
        </Flex>
      </Box>
    </Flex>
  );
};

export default SideBar;
