export const PUBLIC_PATHS = {
  LOGIN: "/login",
  REG: "/register",
  REG_TWO: "/accept-invitation/:code",
  FOG_PASS: "/forgot-password",
  VER_PASS: "/code-verification",
  VER_OTP: "/verify-otp/:confirmationCode",
  CH_PASS: "/change-password/",
  CH_NEW_PASS: "/change-new-parent-password/",
};

export const PRIVATE_PATHS = {
  OVERVIEW: "/parent-overview",
  PENDING_ENROLLMENT: "/pending-enrollment",
  SETTINGS: "/settings",
  NOTI: "/notifications",
  REPORT: "/report",
  TRAN_LOG: "/transaction-log",
  PORTAL: "/student-portal",
  SETUP: "/complete-setup",
  PLAN: "/plan-setup",
};
