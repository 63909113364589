import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Flex,
  Image,
  Heading,
  Text,
  Button,
} from "@chakra-ui/react";
import GoBack from "../common/GoBack";

const SuccessModal = ({ isOpen, onClose }) => {
  return (
    <Modal
      isCentered
      trapFocus={false}
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay backdropFilter="auto" backdropBlur="2px" />
      <ModalContent overflowY="auto" borderRadius="20px" bg="#fff" color="#000">
        <ModalHeader>
          <GoBack title="Close" logout />
        </ModalHeader>

        <ModalBody>
          <Flex my={10} justifyContent="center" align="center" flexDir="column">
            <Image src="/assets/success.svg" w="144px" />
            <Heading my={3} fontSize="24px" fontWeight={700}>
              Awesome!
            </Heading>
            <Text textAlign="center" mb={7} color="#828282" fontSize="16px">
              You have successfully updated your card details
            </Text>

            <Button h="65px" w="full" onClick={onClose}>
              Proceed
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
export default SuccessModal;
