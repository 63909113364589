import axiosInstance, { uploadInstance } from "../axiosInstance";
import * as API from "../url";

export const verifyParent = async (query) => {
  const res = await axiosInstance.get(`${API.VERIFY_PARENT}/${query}`);
  return res.data;
};

export const getBankTransfer = async (body) => {
  const res = await axiosInstance.post(API.TRANSFER_AMT, body);
  return res.data;
};

export const getTrans = async ({ queryKey }) => {
  const [, id] = queryKey;
  const res = await axiosInstance.get(
    `${API.GET_TRANS}?parentId=${id}&startDate=2023-01-01&endDate=2024-12-31&page=1`
  );
  return res.data;
};

export const getPlanCount = async () => {
  const res = await axiosInstance.get(API.GET_PLAN_COUNT);
  return res.data;
};

export const getOverdue = async () => {
  const res = await axiosInstance.get(API.OVERDUE);
  return res.data;
};

export const createAcc = async (body) => {
  const res = await axiosInstance.post(API.CREATE_ACC, body);
  return res.data;
};

export const createLencoAcc = async (body) => {
  const res = await axiosInstance.post(API.CREATE_LENCO, body);
  return res.data;
};

export const changePassword = async (body) => {
  const res = await axiosInstance.post(API.CHANGE_PASS, body);
  return res.data;
};

export const changeNewParentPassword = async (body) => {
  const res = await axiosInstance.post(API.CHANGE_NEW_PARENT_PASS, body);
  return res.data;
};

export const uploadId = async (body) => {
  const res = await uploadInstance.post(API.UPLOAD_ID, body);
  return res.data;
};

export const getBank = async (body) => {
  const res = await axiosInstance.post(API.GET_BANKS, body);
  return res.data;
};

export const getNoti = async ({ queryKey }) => {
  const [, id] = queryKey;
  const res = await axiosInstance.get(`${API.GET_NOTI}?parentId=${id}`);
  return res.data;
};

export const readNoti = async (body) => {
  const res = await axiosInstance.put(API.READ_NOTI, body);
  return res.data;
};

export const deleteNoti = async (query) => {
  const res = await axiosInstance.delete(API.DELETE_NOTI(query));
  return res.data;
};

export const uploadLogo = async (body) => {
  const res = await uploadInstance.post(API.UPLOAD_LOGO, body);
  return res.data;
};
export const getTerms = async ({ queryKey }) => {
  const [, id] = queryKey;
  const res = await axiosInstance.get(
    `${API.GET_TERM}?offset=&limit=&schoolId=${id}`
  );
  return res.data;
};

export const getAllDivisions = async ({ queryKey }) => {
  const [, id] = queryKey;
  const res = await axiosInstance.get(
    `${API.GET_DIVISION}?status=Active&schoolId=${id}`
  );
  return res.data;
};

export const getExtra = async (query) => {
  const res = await axiosInstance.get(API.GET_EXTRAS(query.divId, query.id));
  return res.data;
};

export const getDefaultExtras = async ({ queryKey }) => {
  const [, id] = queryKey;
  const res = await axiosInstance.get(
    `${API.GET_DEFAULT_EXTRAS}?schoolId=${id}`
  );
  return res.data;
};
export const addStudent = async (body) => {
  const res = await axiosInstance.post(API.ADD_PARENT, body);
  return res.data;
};

export const acceptInviteCode = async (query) => {
  const res = await axiosInstance.get(`${API.ACCEPT_INVITE_CODE}/${query}`);
  return res.data;
};

export const getParentPlans = async () => {
  const res = await axiosInstance.get(API.GET_PARENT_PLANS);
  return res.data;
};

export const getParentWards = async () => {
  const res = await axiosInstance.get(API.GET_PARENT_WARDS);
  return res.data;
};

export const getDailyPlanSummary = async () => {
  const res = await axiosInstance.get(API.GET_DAILY_PLAN_SUMMARY);
  return res.data;
};

export const getWeeklyPlanSummary = async () => {
  const res = await axiosInstance.get(API.GET_WEEKLY_PLAN_SUMMARY);
  return res.data;
};

export const getMonthlyPlanSummary = async () => {
  const res = await axiosInstance.get(API.GET_MONTHLY_PLAN_SUMMARY);
  return res.data;
};

export const getOneOffPlanSummary = async () => {
  const res = await axiosInstance.get(API.GET_ONE_OFF_PLAN_SUMMARY);
  return res.data;
};

export const getFullYearPlanSummary = async () => {
  const res = await axiosInstance.get(API.GET_FULL_YEAR_PLAN_SUMMARY);
  return res.data;
};

export const getNewStudentEnrollment = async () => {
  const res = await axiosInstance.get(API.GET_NEW_STUDENT_ENROLLMENT);
  return res.data;
};

export const getPlanTotalAmount = async ({ queryKey }) => {
  const [, parentId] = queryKey;
  const res = await axiosInstance.get(
    `${API.GET_PLAN_TOTAL_AMOUNT}/${parentId}`
  );
  return res.data;
};

export const getPlanOutstanding = async ({ queryKey }) => {
  const [, parentId] = queryKey;
  const res = await axiosInstance.get(
    `${API.GET_PLAN_OUTSTANDING}/${parentId}`
  );
  return res.data;
};

export const getPlanTotalPaid = async ({ queryKey }) => {
  const [, parentId] = queryKey;
  const res = await axiosInstance.get(`${API.GET_PLAN_TOTAL_PAID}/${parentId}`);
  return res.data;
};
