import React, { useEffect } from "react";
import Pages from "./routes";

const App = () => {
  const imagesToPreload = [
    "/assets/atm.svg",
    "/assets/av.svg",
    "/assets/av1.svg",
    "/assets/av2.svg",
    "/assets/acc.jpg",
    "/assets/copy.svg",
    "/assets/error.gif",
    "/assets/success.gif",
    "/assets/loading.gif",
    "/assets/share.svg",
    "/assets/wema.jpg",
    "/assets/fbs.jpg",
    "/assets/providus.jpg",
    "/assets/av3.svg",
    "/assets/av4.svg",
    "/assets/bank.svg",
    "/assets/banks.svg",
    "/assets/bell.svg",
    "/assets/bank.jpg",
    "/assets/calendar.svg",
    "/assets/call.svg",
    "/assets/cancel.svg",
    "/assets/cap.svg",
    "/assets/catFilt.svg",
    "/assets/check.svg",
    "/assets/dots.svg",
    "/assets/edit.svg",
    "/assets/flutter.svg",
    "/assets/fold.svg",
    "/assets/folder.svg",
    "/assets/kyFilt.svg",
    "/assets/logo.png",
    "/assets/logout.svg",
    "/assets/mail.svg",
    "/assets/monnify.svg",
    "/assets/mtn.svg",
    "/assets/note.svg",
    "/assets/pass.svg",
    "/assets/payment.svg",
    "/assets/pf.svg",
    "/assets/pfp.svg",
    "/assets/pin.svg",
    "/assets/plan.svg",
    "/assets/prof.svg",
    "/assets/remita.svg",
    "/assets/search.svg",
    "/assets/side-logo.svg",
    "/assets/slide1.svg",
    "/assets/slide2.svg",
    "/assets/slide3.svg",
    "/assets/success.svg",
    "/assets/tick.svg",
    "/assets/tran.svg",
    "/assets/trash.svg",
    "/assets/upload.svg",
    "/assets/users.svg",
    "/assets/verified.svg",
    "/assets/warn.svg",
    "/assets/week.svg",
    "/assets/welcome-back.svg",
    "/assets/welcome.svg",
  ];

  const preloadImages = () => {
    imagesToPreload.forEach((imagePath) => {
      const link = document.createElement("link");
      (link.rel = "preload"), (link.href = imagePath);
      (link.as = "image"), document.head.appendChild(link);
    });
  };
  useEffect(() => {
    preloadImages();
  }, []);

  return <Pages />;
};

export default App;
