import React from "react";
import { Box, Flex, Text } from "@chakra-ui/layout";
import { Image } from "@chakra-ui/image";
import { useLogOut } from "../../utils/helper";
import { IoIosArrowForward } from "react-icons/io";
import {
  accounts,
  activeStyle,
  general,
  payments,
} from "../../components/common/constants";
import { NavLink } from "react-router-dom";
import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
} from "@chakra-ui/react";
import { WarningIcon } from "../../assets";
import { useGetUser } from "../../services/query/account";
import useCustomToast from "../../utils/notifications";
import { useGetParentPlans } from "../../services/query/parent";

const SideDrawer = ({ isOpen, onClose }) => {
  const logOut = useLogOut();
  const { warningToast } = useCustomToast();
  const { data: parent } = useGetUser();
  const { data: parentPlans } = useGetParentPlans();

  return (
    <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent bgColor="red" color="#000">
        <DrawerBody p={0} overflowY="scroll">
          <Flex
            flexDir="column"
            justifyContent="space-between"
            position={"fixed"}
            zIndex="5"
            w="full"
            borderRight="0.5px solid rgba(104, 132, 202, 0.5)"
            bg="#0F172B"
          >
            <Box h="100vh" overflowY="scroll">
              <Flex
                bg="#0F172B"
                pos="sticky"
                px="20px"
                pt="35px"
                top="0"
                borderBottom="0.5px solid rgba(104, 132, 202, 0.5)"
                pb="35px"
                justifyContent="space-between"
                align="center"
              >
                <Flex gap="13px" align="center">
                  <Image src="/assets/side-logo.svg" w="140px" h="50px" />
                </Flex>
                <Box
                  px="12px"
                  py="5px"
                  bg="#fff"
                  color="#0F172B"
                  rounded="full"
                >
                  <Text fontSize="12px">Parent Portal</Text>
                </Box>
              </Flex>

              {parentPlans?.count === 0 && (
                <Box
                  mx="20px"
                  my="30px"
                  bg="#fff"
                  py="16px"
                  px="22px"
                  borderRadius="10px"
                >
                  <Flex
                    borderBottom="1px solid #e0e0e0"
                    pb="12px"
                    gap="7px"
                    align="center"
                  >
                    <Text fontSize="14px" fontWeight={500}>
                      PENDING
                    </Text>
                    <Flex
                      align="center"
                      justifyContent="center"
                      bg="#000"
                      rounded="full"
                      fontSize="10px"
                      w="20px"
                      h="20px"
                      color="#2463EB"
                    >
                      {parent?.data?.wardCount === 0 && parentPlans?.count === 0
                        ? "02"
                        : parentPlans?.count && parent?.data?.wardCount === 0
                        ? "01"
                        : parentPlans?.count === 0 &&
                          parent?.data?.wardCount > 0
                        ? "01"
                        : parentPlans?.count &&
                          parent?.data?.wardCount > 0 &&
                          ""}
                    </Flex>
                  </Flex>
                  {((parentPlans?.count === 0 &&
                    parent?.data?.wardCount === 0) ||
                    (parentPlans?.count && parent?.data?.wardCount === 0) ||
                    (parentPlans?.count === 0 &&
                      parent?.data?.wardCount > 0)) && (
                    <NavLink to="/complete-setup">
                      <Flex
                        cursor="pointer"
                        mt="20px"
                        justifyContent="space-between"
                        align="center"
                        onClick={() =>
                          parent?.data?.wardCount > 0 &&
                          parent?.data?.user?.status === "Pending-Enrollment"
                            ? (warningToast("Please wait for approval"),
                              onClose())
                            : ""
                        }
                      >
                        <Flex gap="12px" align="center">
                          <WarningIcon fill="#E81313" />
                          <Text fontSize="14px" color="#E81313">
                            Complete Setup
                          </Text>
                        </Flex>
                        <IoIosArrowForward color="#666666" />
                      </Flex>
                    </NavLink>
                  )}

                  <NavLink
                    onClick={() =>
                      parent?.data?.wardCount === 0
                        ? (warningToast("Please Enroll a ward"), onClose())
                        : parent?.data?.wardCount > 0 &&
                          parent?.data?.user?.status === "Pending-Enrollment"
                        ? (warningToast("Please wait for approval"), onClose())
                        : ""
                    }
                    to={
                      parent?.data?.wardCount === 0
                        ? ""
                        : parent?.data?.wardCount > 0 &&
                          parent?.data?.user?.status === "Pending-Enrollment"
                        ? "/pending-enrollment"
                        : "/plan-setup"
                    }
                  >
                    <Flex
                      gap="11px"
                      mt="25px"
                      px="18px"
                      py="15px"
                      borderRadius="10px"
                    >
                      <Image src="/assets/users.svg" />
                      <Text fontSize="14px" fontWeight={500} color="#2463EB">
                        {" "}
                        Choose a Plan
                      </Text>
                    </Flex>
                  </NavLink>
                </Box>
              )}

              <Box mx="20px" mt="30px">
                <Text
                  mb="10px"
                  fontSize="12px"
                  fontWeight={500}
                  color="#4F4F4F"
                >
                  GENERAL
                </Text>
                {general?.map((item, i) => (
                  <Box
                    key={i}
                    onClick={() =>
                      parent?.data?.wardCount > 0 &&
                      parentPlans?.count === 0 &&
                      parent?.data?.user?.status === "Pending-Enrollment"
                        ? (warningToast("Please wait for approval"),
                          navigate("/pending-enrollment"),
                          onClose())
                        : parentPlans?.count > 0 &&
                          parent?.data?.wardCount > 0 &&
                          parent?.data?.user?.status !== "Pending-Enrollment"
                        ? ("", onClose())
                        : parent?.data?.wardCount === 0
                        ? (warningToast("Please Enroll a Ward"), onClose())
                        : (warningToast("Please Choose a Plan"),
                          navigate("/plan-setup"),
                          onClose())
                    }
                    _hover={{
                      backgroundColor: "semiBlue",
                      px: "20px",
                      mx: "-20px",
                    }}
                  >
                    <NavLink
                      to={
                        parentPlans?.count > 0 &&
                        parent?.data?.wardCount > 0 &&
                        parent?.data?.user?.status !== "Pending-Enrollment"
                          ? item.path
                          : parent?.data?.wardCount > 0 &&
                            parentPlans?.count === 0 &&
                            parent?.data?.user?.status === "Pending-Enrollment"
                          ? ""
                          : ""
                      }
                      style={({ isActive }) =>
                        isActive
                          ? { ...activeStyle }
                          : {
                              ...activeStyle,
                              backgroundColor: "",
                              fontWeight: 400,
                              borderRight: "",
                              color: "gray",
                            }
                      }
                    >
                      <Flex>
                        <Box w="16px" h="16px" mr="11px">
                          {location.pathname === item.path
                            ? item.sec
                            : item.icon}
                        </Box>
                        {item.name}
                      </Flex>
                    </NavLink>
                  </Box>
                ))}
              </Box>

              <Box mt="40px" mx="20px">
                <Text
                  mb="10px"
                  fontSize="12px"
                  fontWeight={500}
                  color="#4F4F4F"
                >
                  PAYMENTS
                </Text>
                {payments?.map((item, i) => (
                  <Box
                    key={i}
                    onClick={() =>
                      parent?.data?.wardCount > 0 &&
                      parentPlans?.count === 0 &&
                      parent?.data?.user?.status === "Pending-Enrollment"
                        ? (warningToast("Please wait for approval"),
                          navigate("/pending-enrollment"),
                          onClose())
                        : parentPlans?.count > 0 &&
                          parent?.data?.wardCount > 0 &&
                          parent?.data?.user?.status !== "Pending-Enrollment"
                        ? ("", onClose())
                        : parent?.data?.wardCount === 0
                        ? (warningToast("Please Enroll a Ward"), onClose())
                        : (warningToast("Please Choose a Plan"),
                          navigate("/plan-setup"),
                          onClose())
                    }
                    _hover={{
                      backgroundColor: "semiBlue",
                      px: "20px",
                      mx: "-20px",
                    }}
                  >
                    <NavLink
                      to={
                        parentPlans?.count > 0 &&
                        parent?.data?.wardCount > 0 &&
                        parent?.data?.user?.status !== "Pending-Enrollment"
                          ? item.path
                          : parent?.data?.wardCount > 0 &&
                            parentPlans?.count === 0 &&
                            parent?.data?.user?.status === "Pending-Enrollment"
                          ? ""
                          : ""
                      }
                      style={({ isActive }) =>
                        isActive
                          ? { ...activeStyle }
                          : {
                              ...activeStyle,
                              backgroundColor: "",
                              fontWeight: 400,
                              borderRight: "",
                              color: "gray",
                            }
                      }
                    >
                      <Flex>
                        <Box w="16px" h="16px" mr="11px">
                          {location.pathname === item.path
                            ? item.sec
                            : item.icon}
                        </Box>
                        {item.name}
                      </Flex>
                    </NavLink>
                  </Box>
                ))}
              </Box>

              <Box mb="80px" mt="40px" mx="20px">
                <Text
                  mb="10px"
                  fontSize="12px"
                  fontWeight={500}
                  color="#4F4F4F"
                >
                  ACCOUNT
                </Text>
                {accounts?.map((item, i) => (
                  <Box
                    key={i}
                    onClick={() =>
                      parent?.data?.wardCount > 0 &&
                      parentPlans?.count === 0 &&
                      parent?.data?.user?.status === "Pending-Enrollment"
                        ? (warningToast("Please wait for approval"),
                          navigate("/pending-enrollment"),
                          onClose())
                        : parentPlans?.count > 0 &&
                          parent?.data?.wardCount > 0 &&
                          parent?.data?.user?.status !== "Pending-Enrollment"
                        ? ("", onClose())
                        : parent?.data?.wardCount === 0
                        ? (warningToast("Please Enroll a Ward"), onClose())
                        : (warningToast("Please Choose a Plan"),
                          navigate("/plan-setup"),
                          onClose())
                    }
                    _hover={{
                      backgroundColor: "semiBlue",
                      px: "20px",
                      mx: "-20px",
                    }}
                  >
                    <NavLink
                      to={
                        parentPlans?.count > 0 &&
                        parent?.data?.wardCount > 0 &&
                        parent?.data?.user?.status !== "Pending-Enrollment"
                          ? item.path
                          : parent?.data?.wardCount > 0 &&
                            parentPlans?.count === 0 &&
                            parent?.data?.user?.status === "Pending-Enrollment"
                          ? ""
                          : ""
                      }
                      style={({ isActive }) =>
                        isActive
                          ? { ...activeStyle }
                          : {
                              ...activeStyle,
                              backgroundColor: "",
                              fontWeight: 400,
                              borderRight: "",
                              color: "gray",
                            }
                      }
                    >
                      <Flex>
                        <Box w="16px" h="16px" mr="11px">
                          {location.pathname === item.path
                            ? item.sec
                            : item.icon}
                        </Box>
                        {item.name}
                      </Flex>
                    </NavLink>
                  </Box>
                ))}
              </Box>

              <Flex
                fontSize="14px"
                fontWeight={400}
                cursor="pointer"
                color="#E81313"
                onClick={logOut}
                align="center"
                gap="11px"
                mb="110px"
                mx="25px"
              >
                <Image src="/assets/logout.svg" />
                Log Out
              </Flex>
            </Box>
          </Flex>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default React.memo(SideDrawer);
