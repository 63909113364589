import { useMutation, useQuery } from "react-query";
import {
  addBank,
  createPin,
  setTerm,
  getSchool,
  getUser,
  inviteParent,
  createProfile,
  addStudent,
  getParent,
  getBank,
  createPlan,
  verifyPayment,
  getPlanTransaction,
  verifyPasswordOtp,
  sendPasswordOtp,
  updateUser,
  sendLoginPasswordOtp,
  verifyLoginPasswordOtp,
  verifyLoginOtp,
  confirmTransfer,
} from "../api/account";
import * as queryKey from "../queryKeys";

export const useCreatePin = (options = {}) => {
  const { mutate, isLoading } = useMutation(createPin, {
    mutationKey: queryKey.CREATE_PIN,
    ...options,
  });

  return { mutate, isLoading };
};

export const useUpdateUser = (options = {}) => {
  const { mutate, isLoading } = useMutation(updateUser, {
    mutationKey: queryKey.UPDATE_USER,
    ...options,
  });

  return { mutate, isLoading };
};

export const useVerifyPasswordOtp = (options = {}) => {
  const { mutate, isLoading } = useMutation(verifyPasswordOtp, {
    mutationKey: queryKey.VERIFY_OTP,
    ...options,
  });

  return { mutate, isLoading };
};

export const useVerifyLoginPasswordOtp = (options = {}) => {
  const { mutate, isLoading } = useMutation(verifyLoginPasswordOtp, {
    mutationKey: "VERIFY_LOGIN_OTP",
    ...options,
  });

  return { mutate, isLoading };
};

export const useVerifyLoginOtp = (options = {}) => {
  const { mutate, isLoading } = useMutation(verifyLoginOtp, {
    mutationKey: "VERIFY_LOG_OTP",
    ...options,
  });

  return { mutate, isLoading };
};

export const useSendPasswordOtp = (options = {}) => {
  const { mutate, isLoading } = useMutation(sendPasswordOtp, {
    mutationKey: queryKey.SEND_PASS_OTP,
    ...options,
  });

  return { mutate, isLoading };
};

export const useSendLoginPasswordOtp = (options = {}) => {
  const { mutate, isLoading } = useMutation(sendLoginPasswordOtp, {
    mutationKey: "SEND_LOGIN_PASS_OTP",
    ...options,
  });

  return { mutate, isLoading };
};

export const useCreatePlan = (options = {}) => {
  const { mutate, isLoading } = useMutation(createPlan, {
    mutationKey: queryKey.CREATE_PLAN,
    ...options,
  });

  return { mutate, isLoading };
};

export const useConfirmTransfer = (options = {}) => {
  const { mutate, isLoading } = useMutation(confirmTransfer, {
    mutationKey: queryKey.CONFIRM_TRANSFER,
    ...options,
  });

  return { mutate, isLoading };
};

export const useVerifyPayment = (options = {}) => {
  const { mutate, isLoading } = useMutation(verifyPayment, {
    mutationKey: queryKey.VERIFY_PAYMENT,
    ...options,
  });

  return { mutate, isLoading };
};

export const useInviteParent = (options = {}) => {
  const { mutate, data, isLoading } = useMutation(inviteParent, {
    mutationKey: queryKey.INV_PARENT,
    ...options,
  });

  return { mutate, data, isLoading };
};

export const useAddStudent = (options = {}) => {
  const { mutate, data, isLoading } = useMutation(addStudent, {
    mutationKey: queryKey.ADD_SCHOOL,
    ...options,
  });

  return { mutate, data, isLoading };
};

export const useAddBank = (options = {}) => {
  const { mutate, isLoading } = useMutation(addBank, {
    mutationKey: queryKey.ADD_BANK,
    ...options,
  });

  return { mutate, isLoading };
};

export const useSetTerm = (options = {}) => {
  const { mutate, isLoading } = useMutation(setTerm, {
    mutationKey: queryKey.SET_TERM,
    ...options,
  });

  return { mutate, isLoading };
};

export const useCreateProfile = (options = {}) => {
  const { mutate, isLoading } = useMutation(createProfile, {
    mutationKey: queryKey.CREATE_PROFILE,
    ...options,
  });

  return { mutate, isLoading };
};

export const useGetUser = (options = {}) => {
  const { data, isLoading, refetch } = useQuery([queryKey.GET_USER], getUser, {
    ...options,
  });

  return { data, isLoading, refetch };
};

export const useGetPlanTransaction = (parentId = "", options = {}) => {
  const { data, isLoading, refetch } = useQuery(
    [queryKey.GET_PLAN_TRANSACTION, parentId],
    getPlanTransaction,
    {
      ...options,
    }
  );

  return { data, isLoading, refetch };
};

export const useGetBank = (id = "", options = {}) => {
  const { data, isLoading, refetch } = useQuery(
    [queryKey.GET_BANK, id],
    getBank,
    {
      ...options,
    }
  );

  return { data, isLoading, refetch };
};

export const useGetParent = (id = "", options = {}) => {
  const { data, isLoading, refetch } = useQuery(
    [queryKey.GET_PARENT, id],
    getParent,
    {
      ...options,
    }
  );

  return { data, isLoading, refetch };
};

export const useGetSchool = (id = "", options = {}) => {
  const { data, isLoading, refetch } = useQuery(
    [queryKey.GET_SCHOOL, id],
    getSchool,
    {
      ...options,
    }
  );

  return { data, isLoading, refetch };
};

// export const useGetAllDivisions = (options = {}) => {
//   const { data, isLoading, refetch } = useQuery(
//     [queryKey.GET_DIVISION],
//     getAllDivisions,
//     {
//       ...options,
//     }
//   );

//   return { data, isLoading, refetch };
// };
