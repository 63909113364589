export const VERIFY_ACC = "banks/resolveBankName";
export const CREATE_SCHOOL_OWNER =
  process.env.REACT_APP_BASE_URL + "school/createSchoolOwner";
export const VERIFY_EMAIL =
  process.env.REACT_APP_BASE_URL + "users/updateEmail";
export const CHANGE_PASS = "change-parent-password";
export const CHANGE_NEW_PARENT_PASS = "change-new-parent-password";
export const UPDATE_USER = "update-profile";
export const VERIFY_OTP = "verify-reset-parent";
export const VERIFY_LOGIN_OTP = "verify-reset-parent-on-login";
export const VERIFY_OTPP = "verify-otp-for-password-change";
export const SEND_PASS_OTP = "password-reset-parent";
export const RESEND_LOGIN_OTP = "resend-parent-otp";
export const SEND_LOGIN_PASS_OTP = "password-reset-parent-on-login";
export const VERIFY_PARENT = "/confirm-parent";
export const OVERDUE = "/get-parent-overdue";
export const SEND_EMAIL_TOKEN =
  process.env.REACT_APP_BASE_URL + "users/sendEmailToken";
export const LOGIN = process.env.REACT_APP_BASE_URL + "login-parent";
export const GET_USER = "/parent/get-parent-data";
export const CREATE_SCHOOL =
  process.env.REACT_APP_BASE_URL + "school/createSchool";
export const CREATE_PIN = "users/createTransactionPin";
export const UPLOAD_ID = "upload-parent-id";
export const GET_SCHOOL = "school";
export const GET_BANKS = "get-edupali-bank-details";
export const CREATE_PLAN = "create-plan";
export const CONFIRM_TRANSFER = "confirm-transfer";
export const VERIFY_PAYMENT = "verify-payment";
export const BANK_CODE = "banks/getBanks";
export const BANKS = "get-banks";
export const INV_PARENT = "school/inviteParent";
export const ADD_SCHOOL = "school/createStudent";
export const ADD_BANK = "create-parent-bank-account";
export const GET_BANK = "get-parent-bank-details";
export const SET_TERM = "school/createEnrollment";
export const CREATE_PROFILE = "school/createDivision";
export const GET_PLAN_TRANSACTION = "get-parent-plan-transactions";
export const UPLOAD_LOGO = "upload-parent-avatar";
// export const GET_DIVISION = 'school/getAllDivisions';
// export const GET_TERM = "school/getAllEnrollments";
export const GET_PARENT = "school/getAllParents/";
export const GET_NOTI = "get-parent-notifications";
export const READ_NOTI = "update-notification-status";
export const DELETE_NOTI = (id = "") => `delete-notification/${id}`;
export const TRANSFER_AMT = "pay-with-transfer";
export const COUNTRIES = "https://ipapi.co/json/";
export const CREATE_ACC = "create-paystack-account";
export const CREATE_LENCO = "create-lenco-virtual-account";
export const GET_TRANS = "get-parent-transactions";
export const GET_PLAN_COUNT = "plan-duration-left";
export const GET_TERM = "parent/get-enrollments";
export const GET_DIVISION = "parent/get-divisions";
export const GET_EXTRAS = (divId = "", id = "") =>
  `parent/get-extras/?divisionId=${divId}&schoolId=${id}`;
export const GET_DEFAULT_EXTRAS = "get-school-default-extras";
export const ADD_PARENT = "parent/create-student";
export const GET_CODE = (id = "") => `accept-invitation/${id}`;
export const ACCEPT_INVITE_CODE = "accept-invitation";
export const SIGN_UP_INVITE =
  process.env.REACT_APP_BASE_URL + "signup-with-invite-link";
export const GET_PARENT_PLANS = "parent/get-plans?status&page=1";
export const GET_PARENT_WARDS = "parent/get-parent-wards";
export const GET_MONTHLY_PLAN_SUMMARY = "parent/get-monthly-plan-details";
export const GET_WEEKLY_PLAN_SUMMARY = "parent/get-weekly-plan-details";
export const GET_DAILY_PLAN_SUMMARY = "parent/get-daily-plan-details";
export const GET_ONE_OFF_PLAN_SUMMARY = "parent/get-one-off-plan-details";
export const GET_FULL_YEAR_PLAN_SUMMARY = "parent/get-full-year-plan-details";
export const GET_NEW_STUDENT_ENROLLMENT = "parent/get-new-student-enrollment";
export const GET_PLAN_TOTAL_AMOUNT = "parent/get-plan-total-amount";
export const GET_PLAN_OUTSTANDING = "parent/get-plan-outstanding";
export const GET_PLAN_TOTAL_PAID = "parent/get-plan-total-paid";
