import {
  AuditIcon,
  DashboardIcon,
  NotificationIcon,
  PortalIcon,
  SettingsIcon,
  TransactionIcon,
} from "../../assets";

export const slideshow = [
  "/assets/slide1.svg",
  "/assets/slide2.svg",
  "/assets/slide3.svg",
  "/assets/slide4.svg",
  "/assets/slide5.svg",
];

export const test = [
  {
    id: 1,
  },
  {
    id: 2,
  },
  {
    id: 3,
  },
  {
    id: 4,
  },
];

export const general = [
  {
    id: 1,
    name: "Parent Overview",
    path: "/parent-overview",
    icon: <DashboardIcon fill="gray" />,
    sec: <DashboardIcon fill="#2463EB" />,
  },
  {
    id: 2,
    name: "Student Portal",
    path: "/student-portal",
    icon: <PortalIcon fill="gray" />,
    sec: <PortalIcon fill="#2463EB" />,
  },
];

export const payments = [
  {
    id: 1,
    name: "Transaction Log",
    path: "/transaction-log",
    icon: <TransactionIcon fill="gray" />,
    sec: <TransactionIcon fill="#2463EB" />,
  },
  {
    id: 2,
    name: "Report",
    path: "/report",
    icon: <AuditIcon fill="gray" />,
    sec: <AuditIcon fill="#2463EB" />,
  },
];

export const accounts = [
  {
    id: 1,
    name: "Notification",
    path: "/notifications",
    icon: <NotificationIcon fill="gray" />,
    sec: <NotificationIcon fill="#2463EB" />,
  },
  {
    id: 2,
    name: "Settings",
    path: "/settings",
    icon: <SettingsIcon fill="gray" />,
    sec: <SettingsIcon fill="#2463EB" />,
  },
];

export const loginOptions = ["Login with Email", "Login with Phone No."];

export const ids = [
  {
    name: "National Identity card",
    value: "nin",
  },
  // {
  //   name: "Voters Card",
  //   value: "vin",
  // },
  // {
  //   name: "International Passport",
  //   value: "passport",
  // },
  // {
  //   name: "Drivers License",
  //   value: "dl",
  // },
];

export const activeStyle = {
  backgroundColor: "#F1F5FE",
  alignItems: "center",
  display: "flex",
  color: "#2463EB",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "21px",
  margin: "0 -20px 8px",
  padding: "13px 25px",
  borderRight: "4px solid #2463EB",
};

export const setup = [
  "Create Transaction Pin",
  "Complete Wallet Setup",
  "Set up School Profile",
  "Onboard Parents",
];

export const settings = ["Profile Information", "Security & Login"];

export const classes = [
  "Creche",
  "KG 1",
  "KG 2",
  "Nursery 1",
  "Nursery 2",
  "Primary 1",
  "Primary 2",
  "Primary 3",
  "Primary 4",
  "Primary 5",
  "Primary 6",
  "JSS 1",
  "JSS 2",
  "JSS 3",
  "SSS 1",
  "SSS 2",
  "SSS 3",
];

export const terms = ["First Term", "Second Term", "Third Term"];

export const classRecord = [
  {
    id: 1,
    title: "Total Parent",
    value: "1050",
  },
  {
    id: 2,
    title: "Total Daily Plan",
    value: "240",
  },
  {
    id: 1,
    title: "Total Weekly Plan",
    value: "405",
  },
  {
    id: 1,
    title: "Total Monthly Plan",
    value: "240",
  },
];

export const classHeader = [
  "Class",
  "Num of Days in a Term",
  "Num of Weeks in a Term",
  "Num of Months in a Term",
  "School Fees",
  "",
];

export const parentHeader = ["Amount", "Date", "Service Charge", "Status", ""];

export const tranFilter = [
  "All Transactions",
  "Fund Transfer",
  "Fund Deposit",
  "ATM Card Transaction",
];

export const parentsHeader = [
  "Name",
  "Email Address",
  "Phone Number",
  "Payment Plan",
  "",
];

export const schoolHeader = [
  "Date",
  "Amount",
  "Balance before",
  "Balance after",
  "Status",
  "",
];
export const preferredExtras = [
  {
    id: 0,
    title: "Dance class",
    price: "30,000",
    selected: false,
  },
  {
    id: 1,
    title: "School bus",
    price: "20,000",
    selected: false,
  },
  {
    id: 2,
    title: "Jet club",
    price: "50,000",
    selected: false,
  },
];
export const classAction = [
  {
    id: 1,
    title: "View Details",
    icon: "/assets/note.svg",
  },
  {
    id: 2,
    title: "Edit Class Record Details",
    icon: "/assets/edit.svg",
  },
  {
    id: 3,
    title: "Delete Class record",
    icon: "/assets/trash.svg",
  },
];

export const customers = [
  {
    id: 1,
    title: "Total Income",
  },
  {
    id: 2,
    title: "Total Withdrawal",
  },
  {
    id: 3,
    title: "Balance",
  },
];

export const oneOff = [
  {
    id: 0,
    title: "One off Term Plan",
    price: "₦ 395",
  },
  {
    id: 1,
    title: "One off Session Plan",
  },
];

export const payOpt = [
  {
    id: 1,
    img: "/assets/atm.svg",
    text: "Pay with card",
  },
  {
    id: 2,
    img: "/assets/banks.svg",
    text: "Bank Transfer",
  },
];
export const plan = [
  {
    id: 0,
    title: "Daily Plan",
    price: "₦ 395",
  },
  {
    id: 1,
    title: "Weekly Plan",
  },
  {
    id: 2,
    title: "Monthly Plan",
  },
];
export const planOption = [
  {
    id: 1,
    title: "One off Plan",
    price: "₦ 395",
  },
  {
    id: 2,
    title: "Split Plans",
  },
];

export const childFee = [
  {
    id: 1,
    title: "Total School Fees",
    fees: "₦ 35,570",
  },
  {
    id: 2,
    title: "Toluwalase (Primary 1)",
    fees: "₦ 10,000",
  },
  {
    id: 3,
    title: "Toluwalase (Primary 5)",
    fees: "₦ 15,000",
  },
  {
    id: 4,
    title: "Toluwalase (Primary 2)",
    fees: "₦ 10,570",
  },
];

export const totalFee = [
  {
    id: 1,
    title: "Total School Fees Per Term",
    fees: "₦ 150,000",
  },
  {
    id: 2,
    title: "Total Paid",
    fees: "₦ 90,000",
  },
  {
    id: 3,
    title: "Total Outstanding",
    fees: "₦ 75,000",
  },
  {
    id: 4,
    title: "Total Overdue",
    fees: "₦ 30,570",
  },
];

export const overview = [
  {
    id: 1,
    title: "Total School Fees",
    fees: "₦ 150,000",
  },
  {
    id: 2,
    title: "Total Enrolled Students",
    fees: "3",
    img: "/assets/cap.svg",
  },
  {
    id: 3,
    title: "Active Plan",
    fees: "Weekly",
    img: "/assets/plan.svg",
  },
  {
    id: 4,
    title: "Total Week Paid",
    fees: "3",
    img: "/assets/week.svg",
  },
];

export const reportGrid = [
  {
    id: 1,
    title: "Wallet Balance",
  },
  {
    id: 2,
    title: "Total Fees Paid",
  },
];

export const reportFilter = ["12 months", "3 months", "30 days", "7 days"];
export const childRecord = [
  {
    id: 0,
    title: "Total School Fees",
    fees: "₦ 35,570",
  },
  {
    id: 1,
    title: "Amount Paid",
    sec: "Amount Paid",
    fees: "₦ 10,000",
  },
  {
    id: 2,
    title: "Amount Left",
    fees: "₦ 15,000",
  },
  {
    id: 3,
    title: "Week Left",
    fees: "4 weeks",
  },
];

export const dashFlow = [
  {
    id: 1,
    title: "Total Daily Income",
  },
  {
    id: 2,
    title: "Total Weekly Income",
  },
  {
    id: 3,
    title: "Total Monthly Income",
  },
];

export const auditGrid = [
  {
    id: 1,
    title: "System Balance",
    val: "₦ 35,570,147",
  },
  {
    id: 2,
    title: "Total Enrolled Parents",
    val: "513",
  },
  {
    id: 3,
    title: "Total Active Parents",
    val: "320",
  },
  {
    id: 4,
    title: "Total Inactive Parents",
    val: "193",
  },
];

export const reconFilter = [
  "Reported Transactions",
  "Suspended/Flagged Accounts",
  "Archived Doc",
];

export const tranTab = ["Parents Activities", "School Activities"];
export const auditTab = ["Audit Log", "Complaints"];

export const parentTab = ["All Parents", "Invitation", "Parents Activities"];
export const notiTab = ["All Notifications", "Read", "Unread"];

export const userflow = [
  {
    id: 1,
    title: "Number of Students",
    val: "4",
  },
  {
    id: 2,
    title: "Total Amount",
    val: "₦ 45,570,147",
  },
  {
    id: 3,
    title: "Amount Paid",
    val: "₦ 45,570,147",
  },
  {
    id: 4,
    title: "Amount Left",
    val: "₦ 10,000,000",
  },
];

export const parentDetailsHeader = ["Name", "Class", "School Fee"];
export const childDetailsHeader = [
  "Transaction",
  "Amount",
  "Recipient",
  "Service Charge",
  "Status",
];

export const notiData = [
  {
    id: 1,
    img: "/assets/av.svg",
    status: "unread",
  },
  {
    id: 2,
    img: "/assets/av.svg",
    status: "unread",
  },
  {
    id: 3,
    img: "/assets/av1.svg",
    status: "read",
  },
  {
    id: 4,
    img: "/assets/a2.svg",
    status: "read",
  },
  {
    id: 5,
    img: "/assets/av3.svg",
    status: "read",
  },
  {
    id: 6,
    img: "/assets/av4.svg",
    status: "unread",
  },
  {
    id: 7,
    img: "/assets/av2.svg",
    status: "unread",
  },
];
